import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "p-relative",
    attrs: {
      "fluid": ""
    }
  }, [_vm.loading.initial ? _c('Loading') : _vm._e(), _vm._v(" "), _c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v("\n        " + _vm._s(_vm.$t('settings.integration.hubspot.title')) + "\n        "), _c('IconButton', {
    attrs: {
      "x-small": false,
      "tooltip": _vm.$t('settings.integration.aboutSetting'),
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _vm.$openAboutHubspotIntegration
    }
  })], 1)]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.isConnected ? _c('button', {
    staticClass: "tw-btn tw-btn-outline-error",
    attrs: {
      "type": "button",
      "disabled": !_vm.canIntegrationEdit
    },
    on: {
      "click": _vm.onClickDisconnect
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.disconnectIntegration')) + "\n    ")]) : _vm._e()], 1), _vm._v(" "), !_vm.canIntegrationEdit ? _c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": "",
      "message": _vm.$t('permissions.cantChangeTo', [_vm.$t('settings.integration.hubspot.title')])
    }
  }) : !_vm.loading.initial && !_vm.isConnected ? _c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": "",
      "message": _vm.$t('error.integrationInvalid', ['HubSpot'])
    }
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.$openHubspotOauthLogin
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.connectIntegration.hubspot')) + "\n    ")])]) : _vm._e(), _vm._v(" "), _c('ContentArea', {
    class: {
      disabled: !_vm.canIntegrationEdit || !_vm.isConnected
    }
  }, [_c('h6', [_vm._v("\n      " + _vm._s(_vm.$t('settings.integration.activitySyncSetting')) + "\n    ")]), _vm._v(" "), _c(VCheckbox, {
    staticClass: "mt-4",
    attrs: {
      "hide-details": "",
      "label": _vm.$t('settings.integration.activitySyncActive')
    },
    on: {
      "change": function change($event) {
        return _vm.changeActiveState($event);
      }
    },
    model: {
      value: _vm.form.notification,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notification", $$v);
      },
      expression: "form.notification"
    }
  }), _vm._v(" "), _c(VForm, {
    ref: "form",
    staticClass: "mt-4",
    class: {
      disabled: !_vm.form.notification
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c(VDivider, {
    staticClass: "mb-6"
  }), _vm._v(" "), _c('SectionSubTitle', [_vm._v("\n        " + _vm._s(_vm.$t('settings.integration.activitySyncStatus')) + "\n      ")]), _vm._v(" "), _vm._l(_vm.shippingStatus, function (item) {
    return _c(VCheckbox, {
      key: item.id,
      staticClass: "mt-4",
      attrs: {
        "hide-details": "",
        "label": item.name,
        "value": item.id
      },
      on: {
        "change": function change($event) {
          return _vm.form.selectStatus.sort();
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(item.name) + "\n          "), item.info ? _c('FloatingTooltip', {
            attrs: {
              "right": "",
              "max-width": "300px"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs;
                return [_c(VIcon, _vm._g(_vm._b({
                  staticClass: "ml-1",
                  attrs: {
                    "small": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v("\n                mdi-information\n              ")])];
              }
            }], null, true)
          }, [_vm._v(" "), _c('span', {
            staticClass: "text-body-2"
          }, [_vm._v(_vm._s(item.info))])]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.form.selectStatus,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "selectStatus", $$v);
        },
        expression: "form.selectStatus"
      }
    });
  })], 2)], 1), _vm._v(" "), _c('ContentArea', {
    class: {
      disabled: !_vm.canIntegrationEdit || !_vm.isConnected
    }
  }, [_c('h6', [_vm._v("\n      " + _vm._s(_vm.$t('settings.integration.syncSetting')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-4 d-flex align-center"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('settings.integration.syncSettingDesc')) + "\n      "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('settings.integration.aboutSetting'),
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _vm.$openAboutHubSpotSyncSettings
    }
  })], 1), _vm._v(" "), _vm.syncStatus !== 'inactive' && _vm.syncStatus !== 'active' ? _c('AlertArea', {
    staticClass: "mt-4",
    attrs: {
      "type": "error",
      "no-margin": "",
      "message": _vm.$t('error.syncCrm', ['HubSpot'])
    }
  }) : _vm._e(), _vm._v(" "), _c(VCheckbox, {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.isSyncActiveContact,
      "hide-details": "",
      "label": _vm.$t('settings.integration.hubspot.syncContact')
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeSyncStatus('hubspot_contact', $event);
      }
    }
  }), _vm._v(" "), !_vm.loading.initial ? _c(VExpandTransition, [_vm.isSyncActiveContact ? _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "right-line"
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('div', {
    staticClass: "text-subtitle-2 secondary-text--text mt-2"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('settings.integration.sync.mappingToContact', ['HubSpot', _vm.$t('contact')])) + "\n          ")]), _vm._v(" "), _c('SyncMapping', {
    staticClass: "mt-2",
    attrs: {
      "crm": "hubspot_contact",
      "object": _vm.objectFields['Contact'],
      "rules": _vm.form.mappingRules
    },
    on: {
      "update:rules": function updateRules($event) {
        return _vm.$set(_vm.form, "mappingRules", $event);
      },
      "update:error": function updateError($event) {
        _vm.hasErrors['Contact'] = $event;
      }
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "right-angle-symbol"
  }), _vm._v(" "), _c(VCheckbox, {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.isSyncActiveAccount,
      "hide-details": "",
      "disabled": !_vm.isSyncActiveContact,
      "label": _vm.$t('settings.integration.hubspot.syncAccount')
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeSyncStatus('hubspot_company', $event);
      }
    }
  })], 1), _vm._v(" "), !_vm.loading.initial ? _c(VExpandTransition, [_vm.isSyncActiveAccount ? _c('div', [_c('div', {
    staticClass: "ml-7"
  }, [_c('div', {
    staticClass: "text-subtitle-2 secondary-text--text mt-2"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('settings.integration.sync.mappingToAccount', ['HubSpot', _vm.$t('account')])) + "\n          ")]), _vm._v(" "), _c('SyncMapping', {
    staticClass: "mt-2",
    attrs: {
      "crm": "hubspot_company",
      "object": _vm.objectFields['Company'],
      "rules": _vm.form.mappingRules
    },
    on: {
      "update:rules": function updateRules($event) {
        return _vm.$set(_vm.form, "mappingRules", $event);
      },
      "update:error": function updateError($event) {
        _vm.hasErrors['Company'] = $event;
      }
    }
  })], 1)]) : _vm._e()]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticClass: "flex-grow-1 flex-basis-1"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('action.back'),
      "icon": "mdi-chevron-left"
    },
    on: {
      "click": _vm.back
    }
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary flex-shrink-0",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.save'))
    },
    on: {
      "click": _vm.submit
    }
  }), _vm._v(" "), _c(VSpacer, {
    staticClass: "flex-basis-1"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };